import React, { useEffect, useState, useRef } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import QuickCheckInfoBanner from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/QuickCheckInfoBanner';
import QuickCheckHeroBanner from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/QuickCheckHeroBanner';
import LearnAboutEligibility from '@cof/graffiti-alley-spray-cans/organisms/QuickCheck/components/LearnAboutEligibility';
import { NaturalSelectionSoloMode } from '@cof/natural-selection';

import { getCorrelationId } from '../lib/correlationId';
import { superScript } from '../lib/utils';
import { addQSIfNeeded } from '../lib/urls';
import LegalContent from '../components/ModalContent/HomePageLegalModalContent';
import QuickCheckLayout from '../components/QuickCheckLayout';
import SEOHelmet from '../components/SEO/SEOHelmet';
import SEO from '../components/SEO/SEO';
import newrelicPageAction from '../lib/newrelicSpa';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import cuid from 'cuid';
import { useConfig } from '../hooks/useConfig';

import QuickCheckCards from '../assets/images/quickcheck-cards.jpg';
import './quickcheck.scss';
import { LANGUAGES } from '../lib/constants';

const { NS, Control, Variation } = NaturalSelectionSoloMode;

export const getUrlWithNewCorrelationId = (url) => {
  const newCorrelationId = cuid();

  return addQSIfNeeded({
    url,
    key: 'corid',
    value: newCorrelationId,
  });
};

const QuickCheckWelcomePage = injectIntl(({ intl }) => {
  const config = useConfig(intl.locale);

  const [quickCheckTargetUrl, setQuickCheckTargetUrl] = useState(() => getUrlWithNewCorrelationId(config.qk));
  const isRedirecting = useRef(false);

  const signalRedirect = () => {
    isRedirecting.current = true;
  };

  // NOTE:
  // *** The functionality below is brittle and relies on nuanced interactions between Spray Cans, teapot, and the browser. ***
  //
  // The core intent is that a new client correlation ID URL parameter is generated for QuickCheck every time a user clicks
  // the QuickCheck button.
  //
  // See https://confluence.kdc.capitalone.com/display/error418/CANCA-22480%3A+CCID+inconsistent+behaviour+on+mobile
  // for a detailed description of the data intent.
  //
  // There are 4 key events which happen a specific order when redirecting a user to QuickCheck:
  // 1. The user visits this QuickCheck welcome page and a new correlation ID is generated
  // 1. The user clicks the QuickCheck button (a link styled as a button)
  // 2. A "link click" Snowplow event is streamed (this is done by Spray Cans) containing the target_url where the user will be redirected
  //    - The target_url field must contain the new correlation ID to allow correlation to future QuickCheck events
  //    - The application_id field must contain the usual WWW correlation ID (not handled here) in order to allow correlation to past events
  // 3. The user is redirected to the QuickCheck target_url with the correct correlation ID as a URL parameter
  // 4. A new correlation ID is generated for the next time the user clicks the QuickCheck button
  //
  // As per the intent, a new correlation ID must be generated every time the user visits QuickCheck.
  // Intuitively, this would happen BEFORE the the event containing the target_url is sent AND before the user is redirected.
  // However, due to the way browsers handle events and the event streaming setup in Spray Cans, this is not possible.
  //
  // To ensure the proper ordering and data integrity, a new ID is generated AFTER the event stream and redirect,
  // by setting up an event listener on the window blur event and integrating it with a piece of ref state.
  // The assumption is that the window will be unfocused since the user is redirected to QuickCheck in a new tab.
  //
  // Hence, the next time the button is clicked the generated ID will be used and will be different from the previous click.
  useEffect(() => {
    const performPostRedirectActions = () => {
      if (isRedirecting.current) {
        setQuickCheckTargetUrl(getUrlWithNewCorrelationId(config.qk));
        newrelicPageAction('QuickCheckClick', {});

        isRedirecting.current = false;
      }
    };

    window.addEventListener('blur', performPostRedirectActions);

    return () => {
      window.removeEventListener('blur', performPostRedirectActions);
    };
  }, [config.qk]);

  const supportsIntersectionObserver =
    typeof window !== 'undefined' ? window.IntersectionObserver || 'IntersectionObserver' in window : undefined;
  const componentRef = React.useRef(null);
  const entry = useIntersectionObserver(componentRef, {});
  const isVisible = !!entry?.isIntersecting;
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (config.isReady) {
      const [currentDomain, currentQS] = quickCheckTargetUrl.split('?');
      const [freshDomain] = config.qk.split('?');
      const shouldAddFrenchQS = intl.locale === 'fr' && currentQS.indexOf(`lang=${LANGUAGES.FRENCH_CANADA}`) === -1;
      if (currentDomain !== freshDomain || shouldAddFrenchQS) {
        const searchParams = new URLSearchParams(window.location.search);
        let updatedUrl = getUrlWithNewCorrelationId(config.qk);
        if (shouldAddFrenchQS) {
          updatedUrl = getUrlWithNewCorrelationId(
            addQSIfNeeded({ url: config.qk, key: 'lang', value: `${LANGUAGES.FRENCH_CANADA}` }),
          );
        }
        searchParams.forEach((value, key) => {
          updatedUrl = addQSIfNeeded({
            url: updatedUrl,
            key,
            value,
          });
        });
        setQuickCheckTargetUrl(updatedUrl);
      }
    }
  }, [config.isReady, config.qk, quickCheckTargetUrl, intl.locale]);

  return (
    <QuickCheckLayout>
      <SEOHelmet
        title={intl.formatMessage({ id: 'pages.quickcheck.page-title' })}
        description={intl.formatMessage({ id: 'pages.quickcheck.page-desc' })}
        lang={intl.locale}
      />
      <section className="page-content-wrapper quickcheck-welcome-page" data-testid="welcome-page-main-wrapper">
        <QuickCheckHeroBanner
          heroText={
            <FormattedMessage
              id="pages.quickcheck.qk-cta-control"
              values={{
                rball: (
                  <LearnAboutEligibility>
                    <sup className="dagger">®</sup>
                  </LearnAboutEligibility>
                ),
                star: <sup>*</sup>,
                MC: (
                  <LearnAboutEligibility>
                    <sup className="dagger">MC</sup>
                  </LearnAboutEligibility>
                ),
              }}
            />
          }
        >
          <div className="qc-hero-banner-button" ref={componentRef}>
            <Link to={quickCheckTargetUrl} className="action button see-cards-button" onClick={signalRedirect}>
              <FormattedMessage id="pages.quickcheck.cta" />
            </Link>
          </div>
        </QuickCheckHeroBanner>
        <div className="qc-info-wrapper">
          <QuickCheckInfoBanner valueProp={<FormattedMessage id="pages.quickcheck.qk-value-prop-control" />} />
        </div>
        <div className="quickcheck-find-card page-content">
          <h2>
            <FormattedMessage id="pages.quickcheck.qk-cards-1" />
          </h2>
          <img className="quickcheck-cards" src={QuickCheckCards} alt="" />
          <p data-testid="card-section-paragraph">
            <FormattedMessage id="pages.quickcheck.qk-cards-2" values={{ star: superScript('star') }} />
          </p>
        </div>
        <HeroBanner backgroundContentClass="quickcheck-helping-canadians-hero-banner">
          <h2>
            <FormattedMessage id="pages.quickcheck.h2-1" />
          </h2>
          <p data-testid="banner-section-paragraph">
            <FormattedMessage id="pages.quickcheck.p1" values={{ star: superScript('star') }} />
          </p>
        </HeroBanner>
        <div id="quickcheck-page-legal" className="home-page-legal" data-for="home-page-legal">
          <ModalLink
            target={LegalContent}
            className="home-page-legal-link"
            title=" "
            linkIcon={false}
            intl={intl}
            size="large"
          >
            <FormattedMessage id="navfooter.legal.legal-updated" />
          </ModalLink>
        </div>
      </section>
      <NS experimentName="solo-mode-data-metrics" correlationId={getCorrelationId()}>
        <Control weight={0.5}>
          <span className="qk-www-solo-control"></span>
        </Control>
        <Variation name="qk-solo-test-variation" weight={0.5}>
          <span className="qk-www-not-solo-control"></span>
        </Variation>
      </NS>
      {(!supportsIntersectionObserver || isAnimating) && (
        <div className={`stickybar-wrapper ${!isVisible ? 'visible' : 'hidden'} ${isAnimating ? 'animating' : ''}`}>
          <div className="qk-sticky-button">
            <Link
              to={quickCheckTargetUrl}
              className="button large action apply-now-button qk-sticky-button-cta"
              onClick={signalRedirect}
            >
              <FormattedMessage id="pages.quickcheck.cta" />
            </Link>
          </div>
        </div>
      )}
    </QuickCheckLayout>
  );
});

export default QuickCheckWelcomePage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.quickcheck.page-title" descriptionKey="pages.quickcheck.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
